var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formData" },
    [
      _c("div", { staticStyle: { height: "20px" } }),
      _c("div", { staticClass: "formNameId" }, [
        _c(
          "p",
          [
            _vm._v("表单名：" + _vm._s(_vm.formItemDetail.formName)),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "1050px" },
                attrs: { plain: "" },
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [_vm._v("返回")]
            )
          ],
          1
        ),
        _c("p", [_vm._v("编号：" + _vm._s(_vm.formItemDetail.id))]),
        _c(
          "div",
          { staticClass: "formTime" },
          [
            _vm._v("时间范围： "),
            _c("el-date-picker", {
              attrs: {
                "value-format": "yyyy/MM/dd HH:mm:ss",
                type: "datetimerange",
                "picker-options": _vm.pickerOptions,
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                align: "right"
              },
              model: {
                value: _vm.time,
                callback: function($$v) {
                  _vm.time = $$v
                },
                expression: "time"
              }
            }),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "20px" },
                attrs: { type: "primary" },
                on: { click: _vm.query }
              },
              [_vm._v("查询")]
            )
          ],
          1
        )
      ]),
      _vm.formItemDetail.fieldList
        ? _c(
            "div",
            { staticClass: "formTab" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.changeTab },
                  model: {
                    value: _vm.tabIndex,
                    callback: function($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "字段统计", name: "first" } },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.fieldIndex,
                            callback: function($$v) {
                              _vm.fieldIndex = $$v
                            },
                            expression: "fieldIndex"
                          }
                        },
                        [
                          _vm._l(_vm.formItemDetail.fieldList, function(
                            item,
                            index
                          ) {
                            return [
                              _c(
                                "el-collapse-item",
                                { key: item.id, attrs: { name: index } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title",
                                        on: {
                                          click: function($event) {
                                            return _vm.collApseItem(index)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(_vm._s(item.name) + " "),
                                        item.total
                                          ? _c("span", [
                                              _vm._v(
                                                "（总计： " +
                                                  _vm._s(item.total) +
                                                  "条）"
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { data: item.tableData }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: { label: item.name },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  item.itemCode ==
                                                    "video_upload" &&
                                                  scope.row.itemContent
                                                    ? [
                                                        _vm._l(
                                                          JSON.parse(
                                                            scope.row
                                                              .itemContent
                                                          ),
                                                          function(
                                                            item,
                                                            index
                                                          ) {
                                                            return [
                                                              _c(
                                                                "el-link",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    type:
                                                                      "primary",
                                                                    href: item,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "查看视频"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ]
                                                    : item.itemCode ==
                                                        "image_upload" &&
                                                      scope.row.itemContent
                                                    ? [
                                                        _vm._l(
                                                          JSON.parse(
                                                            scope.row
                                                              .itemContent
                                                          ),
                                                          function(
                                                            item,
                                                            index
                                                          ) {
                                                            return [
                                                              _c(
                                                                "el-link",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    type:
                                                                      "primary",
                                                                    href: item,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "查看图片"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ]
                                                    : item.itemCode ==
                                                        "file_upload" &&
                                                      scope.row.itemContent
                                                    ? [
                                                        _vm._l(
                                                          JSON.parse(
                                                            scope.row
                                                              .itemContent
                                                          ),
                                                          function(
                                                            item,
                                                            index
                                                          ) {
                                                            return [
                                                              _c(
                                                                "el-link",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    type:
                                                                      "primary",
                                                                    href: item,
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "查看文件"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        )
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              scope.row
                                                                .itemContent ||
                                                                "-"
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "比例" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("el-progress", {
                                                    attrs: {
                                                      type: "circle",
                                                      percentage:
                                                        scope.row
                                                          .completionRate,
                                                      width: 50
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "操作" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.seeData(
                                                            scope.row
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("查看")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c("el-pagination", {
                                    staticStyle: {
                                      "text-align": "right",
                                      "margin-right": "20px",
                                      "margin-top": "20px"
                                    },
                                    attrs: {
                                      "current-page": item.pageNo,
                                      "page-size": item.pageSize,
                                      layout: "total, prev, pager, next",
                                      total: item.total
                                    },
                                    on: {
                                      "size-change": _vm.handleSizeChange,
                                      "current-change": _vm.handleCurrentChange,
                                      "update:currentPage": function($event) {
                                        return _vm.$set(item, "pageNo", $event)
                                      },
                                      "update:current-page": function($event) {
                                        return _vm.$set(item, "pageNo", $event)
                                      }
                                    }
                                  })
                                ],
                                2
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "高级报表", name: "second" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { margin: "20px" },
                          on: { change: _vm.changeType },
                          model: {
                            value: _vm.radio,
                            callback: function($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "day" } }, [
                            _vm._v("按日")
                          ]),
                          _c("el-radio-button", { attrs: { label: "week" } }, [
                            _vm._v("按周")
                          ]),
                          _c("el-radio-button", { attrs: { label: "month" } }, [
                            _vm._v("按月")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "show-summary": "",
                            data: _vm.highTable,
                            border: ""
                          }
                        },
                        [
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                align: "center",
                                label: "二维码表单名称",
                                fixed: "left"
                              }
                            },
                            [
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formItemDetail.formName) +
                                    " "
                                )
                              ]
                            ],
                            2
                          ),
                          _vm._l(_vm.highTable, function(item, index) {
                            return [
                              _vm._l(item, function(value, key) {
                                return _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      align: "center",
                                      label: key + " 填写表单数",
                                      prop: key,
                                      width: "120px"
                                    }
                                  },
                                  [[_vm._v(" " + _vm._s(value || "-") + " ")]],
                                  2
                                )
                              }),
                              _c("el-table-column", {
                                attrs: { label: "合计", fixed: "right" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.amount(scope.row)) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "详情", visible: _vm.showDetail },
          on: {
            "update:visible": function($event) {
              _vm.showDetail = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "detail" },
            [
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.detail } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "填写日期",
                      prop: "createTime"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "填写人",
                      prop: "userName"
                    }
                  }),
                  _vm._l(_vm.detailHeader, function(tr, i) {
                    return _c("el-table-column", {
                      key: i,
                      attrs: {
                        align: "center",
                        label: tr.label,
                        prop: tr.prop
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row[tr.prop] instanceof Array
                                  ? [
                                      _vm._l(scope.row[tr.prop], function(
                                        file,
                                        index
                                      ) {
                                        return [
                                          _c(
                                            "el-link",
                                            {
                                              key: index,
                                              attrs: {
                                                type: "primary",
                                                href: file,
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v("查看")]
                                          )
                                        ]
                                      })
                                    ]
                                  : [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row[tr.prop] || "-") +
                                          " "
                                      )
                                    ]
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }