<template>
  <div class="formData">
    <div style="height: 20px;"></div>
    <div class="formNameId">
      <p>表单名：{{ formItemDetail.formName}}<el-button style="margin-left: 1050px;" plain @click="$router.back()">返回</el-button></p>
      <p>编号：{{ formItemDetail.id }}</p>
      <div class="formTime">时间范围：
        <el-date-picker
          v-model="time"
          value-format="yyyy/MM/dd HH:mm:ss"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right">
        </el-date-picker>
        <el-button style="margin-left: 20px" type="primary" @click="query">查询</el-button>
      </div>
    </div>
    <div class="formTab" v-if="formItemDetail.fieldList">
      <el-tabs v-model="tabIndex" @tab-click="changeTab">
        <el-tab-pane label="字段统计" name="first">
          <el-collapse  v-model="fieldIndex">
            <template v-for="(item, index) of formItemDetail.fieldList">
              <el-collapse-item :name="index" :key="item.id">
                <template slot="title">
                  <div class="title" @click="collApseItem(index)">{{ item.name }} <span v-if="item.total">（总计： {{ item.total }}条）</span> </div>
                </template>
                <el-table
                  :data="item.tableData"
                  style="width: 100%">
                  <el-table-column :label="item.name">
                    <template slot-scope="scope">
                      <template v-if="item.itemCode == 'video_upload' && scope.row.itemContent">
                        <template v-for="(item,index) of JSON.parse(scope.row.itemContent)">
                          <el-link type="primary" :href="item" target="_blank" :key="index">查看视频</el-link>
                        </template>
                      </template>
                      <template v-else-if="item.itemCode == 'image_upload' && scope.row.itemContent">
                        <template v-for="(item,index) of JSON.parse(scope.row.itemContent)">
                          <el-link type="primary" :href="item" target="_blank" :key="index">查看图片</el-link>
                        </template>
                      </template>
                      <template v-else-if="item.itemCode == 'file_upload' && scope.row.itemContent">
                        <template v-for="(item,index) of JSON.parse(scope.row.itemContent)">
                          <el-link type="primary" :href="item" target="_blank" :key="index">查看文件</el-link>
                        </template>
                      </template>
                      <template v-else>
                        {{ scope.row.itemContent || '-' }}
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column label="比例">
                    <template slot-scope="scope">
                      <el-progress type="circle" :percentage="scope.row.completionRate" :width="50"></el-progress>
<!--                      {{ scope.row.completionRate }}-->
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button @click="seeData(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination style="text-align: right;margin-right: 20px;margin-top: 20px;"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="item.pageNo"
                  :page-size="item.pageSize"
                  layout="total, prev, pager, next"
                  :total="item.total">
                </el-pagination>
              </el-collapse-item>
            </template>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="高级报表" name="second">
          <el-radio-group style="margin: 20px" v-model="radio" @change="changeType">
            <el-radio-button label="day">按日</el-radio-button>
            <el-radio-button label="week">按周</el-radio-button>
            <el-radio-button label="month">按月</el-radio-button>
          </el-radio-group>
          <el-table show-summary
            :data="highTable" border
            style="width: 100%">
            <el-table-column align="center" label="二维码表单名称" fixed="left">
              <template>
                {{ formItemDetail.formName }}
              </template>
            </el-table-column>
            <template v-for="(item, index) of highTable">
              <el-table-column align="center" v-for="(value, key) in item" :label="key + ' 填写表单数'" :prop="key" width="120px">
                <template>
                  {{ value || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="合计" fixed="right">
                <template slot-scope="scope">
                  {{ amount(scope.row) }}
                </template>
              </el-table-column>
            </template>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="详情" :visible.sync="showDetail">
      <div class="detail">
        <el-table border :data="detail">
          <el-table-column align="center" label="填写日期" prop="createTime"></el-table-column>
          <el-table-column align="center" label="填写人" prop="userName"></el-table-column>
          <el-table-column align="center" v-for="(tr, i) of detailHeader" :key="i" :label="tr.label" :prop="tr.prop">
            <template slot-scope="scope">
              <template v-if="scope.row[tr.prop] instanceof Array">
                <template v-for="(file, index) of scope.row[tr.prop]">
                  <el-link type="primary" :href="file" target="_blank" :key="index">查看</el-link>
                </template>
              </template>
              <template v-else>
                {{ scope.row[tr.prop] || '-' }}
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryUserForm, fieldCount, getRecordInfo, getPeriodRecordCount } from '@/api/createForm.js'
export default {
  name: 'formData',
  data () {
    return {
      formItemDetail: {}, // 各表单字段详情
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      time: [],
      tabIndex: 'first',
      fieldIndex: [0],
      clickIndex: 0,
      showDetail: false,
      detail: [{}],
      detailHeader: [],
      radio: 'day',
      highTable: []

    }
  },
  mounted () {
    if (this.$route.query.formId) {
      this.query()
    }
  },
  methods: {
    query () {
      if (this.tabIndex === 'first') {
        this.init()
      } else {
        this.queryHighInit()
      }
    },
    // 查询表单详情
    init () {
      queryUserForm({
        formId: this.$route.query.formId
      }).then(res => {
        if (res.code === 200) {
          this.formItemDetail = {
            formName: res.data.userForm.formName,
            id: res.data.userForm.id,
            fieldList: res.data.formItemOptionDTO.userFormItem.filter((option) => {
              return option.itemCode !== 'title' && option.itemCode !== 'describe'
            }).map((item, index) => {
              const { id, itemCode, name } = item
              return {
                id,
                index,
                itemCode,
                name,
                tableData: [],
                headerList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0
              }
            })
          }
          this.queryFieldDetail(this.formItemDetail.fieldList[0])
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    queryFieldDetail (item) { // 查询字段详情
      const { 0: startTime, 1: endTime } = this.time || []
      fieldCount({
        formId: this.$route.query.formId,
        itemId: item.id,
        startTime,
        endTime,
        pageNo: item.pageNo,
        pageSize: item.pageSize
      }).then(res => {
        if (res.code === 200) {
          const { total, list } = res.data
          const { index } = item
          this.formItemDetail.fieldList[index].tableData = list
          this.formItemDetail.fieldList[index].total = total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查看数据
    seeData (row) {
      getRecordInfo({
        recordId: row.recordId
      }).then(res => {
        if (res.code === 200) {
          res.data.forEach((item, index) => {
            this.$set(this.detail[0], 'createTime', item.createTime)
            this.$set(this.detail[0], 'userName', item.userName)
            if (item.content && (item.itemCode === 'video_upload' || item.itemCode === 'image_upload' || item.itemCode === 'file_upload')) {
              this.$set(this.detail[0], item.itemId, JSON.parse(item.content))
            } else if (item.itemCode === 'checkbox' && item.content) {
              this.$set(this.detail[0], item.itemId, JSON.parse(item.content).join(','))
            } else {
              this.$set(this.detail[0], item.itemId, item.content)
            }
          })
          this.detailHeader = res.data.map((item) => {
            return {
              label: item.itemName,
              prop: item.itemId
            }
          })
          this.showDetail = true
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 修改分页数据
    handleSizeChange (val) {
      this.formItemDetail.fieldList[this.clickIndex].pageSize = val
      this.formItemDetail.fieldList[this.clickIndex].pageNo = 1
      this.queryFieldDetail(this.formItemDetail.fieldList[this.clickIndex])
    },
    handleCurrentChange (val) {
      this.formItemDetail.fieldList[this.clickIndex].pageNo = val
      this.queryFieldDetail(this.formItemDetail.fieldList[this.clickIndex])
    },
    collApseItem (index) {
      this.clickIndex = index
      this.queryFieldDetail(this.formItemDetail.fieldList[index])
    },
    // 查询高级报表
    queryHighInit () {
      const { 0: startTime, 1: endTime } = this.time || []
      getPeriodRecordCount({
        endTime,
        formId: this.$route.query.formId,
        period: this.radio,
        startTime
      }).then(res => {
        if (res.code === 200) {
          this.highTable = res.data.periodCountList.map((item, index) => {
            const row = {}
            item.fieldCountList.forEach((r, i) => {
              row[r.from + (r.to ? ' - ' + r.to : '')] = Number(r.recordCount)
            })
            return row
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    changeType (e) {
      this.radio = e
      this.queryHighInit()
    },
    changeTab (tab) {
      this.tabIndex = tab.name
      if (!this.highTable.length) {
        this.queryHighInit()
      }
    },
    amount (row) {
      let amount = 0
      for (const k in row) {
        amount += row[k]
      }
      return amount
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./formData.scss";
</style>
